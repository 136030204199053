import React from 'react'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import LayoutLogin from '../components/layout-login'
import { navigate } from 'gatsby'

import { css } from 'react-emotion';

import { handleLogin, isLoggedIn, logout } from '../utils/auth';

import { Button, Form, FormGroup, Label, Input, FormText, Alert, Container, Row, Col } from 'reactstrap';

import { FaRedo } from 'react-icons/fa'
 
const loginStyles = css`

  .load-animation {
    animation: spin .7s infinite linear;
  }

  @keyframes spin {
      from { transform: scale(1) rotate(0deg);}
      to { transform: scale(1) rotate(360deg);}
  }
  
`;

class Login extends React.Component {

  constructor(props){

    super(props)

    this.state = {
      order: ``,
      license: ``,
      loading: false
    }

  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleSubmit = event => {
    this.setState({loading: true})
    this.state.loading = true;
    event.preventDefault()
    return new Promise((resolve, reject) => {
      handleLogin(this.state).then((res) => {
        this.setState({loading: false})
        resolve(true)
      }, (err) => {
        this.setState({loading: false})
        reject(false);
      })
    })
  }

  handleLogout(){
    logout();
    navigate('/login');
  }

  render() {

    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )

    if(isLoggedIn()){
      return (
        <LayoutLogin location={this.props.location} title="Elite Ionic - Angular" back={`/`}>
          <Helmet
            htmlAttributes={{ lang: 'en' }}
            meta={[{ name: 'description', content: siteDescription }]}
            title={siteTitle}>
          </Helmet> 

          <Container className={loginStyles} style={{
            color: `#2d2d2d`,
            marginTop: `40px`,
            backgroundColor: `#f6f6f6`,
            borderRadius: `10px`,
            boxShadow: `0 1px 9px 4px rgba(0,0,0,0.1)`,
            padding: `20px`
          }}>

            <Row>
              <Col xs={{size: 10, offset: 1}} style={{textAlign: `center`}}>
                <h1>You are already logged in</h1> 
                <p>Please <a href="/angular" style={{fontWeight: `bold`}}>click here</a> to proceed to the course.</p>
              </Col>
            </Row>

            <Row>
              <Col xs={{size: 10, offset: 1}} style={{textAlign: `center`}}>
                <p>If you are having trouble accessing the course, please click the log out button below and try again.</p>
                <Button onClick={this.handleLogout}>
                  Log Out
                </Button>
              </Col>              
            </Row>

          </Container>

        </LayoutLogin>        
      )
    } else {

      return (
        <LayoutLogin location={this.props.location} title="Elite Ionic - Angular" back={`/`}>
          <Helmet
            htmlAttributes={{ lang: 'en' }}
            meta={[{ name: 'description', content: siteDescription }]}
            title={siteTitle}>
          </Helmet> 
  
          <Container className={loginStyles} style={{
            color: `#3e3e3e`,
            marginTop: `40px`,
            backgroundColor: `#f6f6f6`,
            borderRadius: `10px`,
            boxShadow: `0 1px 9px 4px rgba(0,0,0,0.1)`,
            padding: `20px`,
          }}>
  
            <Row>

              <Col xs={{size: 10, offset: 1}}>
              
                <svg id="eliteioniclogo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 269.24 59.74">
                        <defs>
                            <style>
                                {`
                                    .cls-1 {
                                        fill: #c1c1c1;
                                    }

                                    .cls-2 {
                                        fill: #2c2c2c;
                                    }
                                `}
                            </style>
                        </defs>
                        <title>eliteionic</title>
                        <g>
                            <g>
                            <path className="cls-1" d="M502.63,846.77q0,0.62,0,1.27t-0.07,1.08h-17a2.16,2.16,0,0,0,.46,1.2,3.92,3.92,0,0,0,1,.91,4.58,4.58,0,0,0,1.34.58,6,6,0,0,0,1.51.19,5.4,5.4,0,0,0,2.57-.55,4.85,4.85,0,0,0,1.56-1.22l7.87,4a11.6,11.6,0,0,1-4.87,4.32,16.48,16.48,0,0,1-7.42,1.58,16.75,16.75,0,0,1-5.33-.86,13.57,13.57,0,0,1-4.58-2.57,12.52,12.52,0,0,1-3.19-4.25,13.69,13.69,0,0,1-1.2-5.9,13.9,13.9,0,0,1,1.1-5.64,12.63,12.63,0,0,1,3-4.27,13.43,13.43,0,0,1,4.46-2.71,15.57,15.57,0,0,1,5.5-1,13.83,13.83,0,0,1,5.42,1,12.31,12.31,0,0,1,4.2,2.88,13,13,0,0,1,2.71,4.39A15.52,15.52,0,0,1,502.63,846.77Zm-9.55-3.6a2.81,2.81,0,0,0-1-2.21,3.89,3.89,0,0,0-2.66-.86,3.83,3.83,0,0,0-2.83,1,3.67,3.67,0,0,0-1.15,2.06h7.63Z" transform="translate(-411.7 -815.24)"></path>
                            <path className="cls-1" d="M506.52,822.87h11.33v36.29H506.52V822.87Z" transform="translate(-411.7 -815.24)"></path>
                            <path className="cls-1" d="M522.79,826.61a5.33,5.33,0,0,1,.46-2.18,5.68,5.68,0,0,1,1.25-1.8,6.11,6.11,0,0,1,1.85-1.22,5.63,5.63,0,0,1,2.26-.46,5.19,5.19,0,0,1,2.16.46,6.31,6.31,0,0,1,1.8,1.22,5.7,5.7,0,0,1,1.25,1.8,5.35,5.35,0,0,1,.46,2.18,5.46,5.46,0,0,1-.46,2.23,5.41,5.41,0,0,1-1.25,1.78,6.13,6.13,0,0,1-1.8,1.18,5.42,5.42,0,0,1-2.16.43,5.89,5.89,0,0,1-2.26-.43,6,6,0,0,1-1.85-1.18,5.39,5.39,0,0,1-1.25-1.78A5.45,5.45,0,0,1,522.79,826.61Zm0.19,7.3h11.09v25.25H523V833.91Z" transform="translate(-411.7 -815.24)"></path>
                            <path className="cls-1" d="M550.2,859.83q-5.09,0-7.54-2.18t-2.45-6.79v-9h-3.55v-7.92h3.5v-5.66h10.75v5.66h5.47v7.92h-5.47v6.72a3.09,3.09,0,0,0,.79,2.47,3.35,3.35,0,0,0,2.18.65,6.67,6.67,0,0,0,1.1-.1,8.53,8.53,0,0,0,.91-0.19V859a9.93,9.93,0,0,1-2.42.62A21.67,21.67,0,0,1,550.2,859.83Z" transform="translate(-411.7 -815.24)"></path>
                            <path className="cls-1" d="M585.77,846.77q0,0.62,0,1.27t-0.07,1.08h-17a2.16,2.16,0,0,0,.46,1.2,3.92,3.92,0,0,0,1,.91,4.58,4.58,0,0,0,1.34.58A6,6,0,0,0,573,852a5.4,5.4,0,0,0,2.57-.55,4.85,4.85,0,0,0,1.56-1.22l7.87,4a11.6,11.6,0,0,1-4.87,4.32,16.48,16.48,0,0,1-7.42,1.58,16.75,16.75,0,0,1-5.33-.86,13.57,13.57,0,0,1-4.58-2.57,12.52,12.52,0,0,1-3.19-4.25,13.69,13.69,0,0,1-1.2-5.9,13.9,13.9,0,0,1,1.1-5.64,12.63,12.63,0,0,1,3-4.27,13.43,13.43,0,0,1,4.46-2.71,15.57,15.57,0,0,1,5.5-1,13.83,13.83,0,0,1,5.42,1,12.31,12.31,0,0,1,4.2,2.88,13,13,0,0,1,2.71,4.39A15.52,15.52,0,0,1,585.77,846.77Zm-9.55-3.6a2.81,2.81,0,0,0-1-2.21,3.89,3.89,0,0,0-2.66-.86,3.83,3.83,0,0,0-2.83,1,3.67,3.67,0,0,0-1.15,2.06h7.63Z" transform="translate(-411.7 -815.24)"></path>
                            </g>
                        </g>
                        <g>
                            <g>
                            <path className="cls-1" d="M594.5,828.2a2.69,2.69,0,0,1-.88,2.06,3,3,0,0,1-2.12.82,2.88,2.88,0,0,1-2.09-.84,2.75,2.75,0,0,1-.86-2,2.81,2.81,0,0,1,.86-2.09,2.88,2.88,0,0,1,2.09-.84,3,3,0,0,1,2.12.84A2.77,2.77,0,0,1,594.5,828.2Zm-0.72,31h-4.51V836.41h4.51v22.75Z" transform="translate(-411.7 -815.24)"></path>
                            <path className="cls-1" d="M622.29,847.73a12.29,12.29,0,0,1-.94,4.85,11.52,11.52,0,0,1-2.59,3.82,11.69,11.69,0,0,1-3.91,2.5,13.67,13.67,0,0,1-9.7,0,11.42,11.42,0,0,1-3.89-2.5,11.7,11.7,0,0,1-2.57-3.82,12.29,12.29,0,0,1-.94-4.85,12.13,12.13,0,0,1,.94-4.82,11.51,11.51,0,0,1,2.57-3.77,11.63,11.63,0,0,1,3.89-2.47,13.67,13.67,0,0,1,9.7,0,11.9,11.9,0,0,1,3.91,2.47,11.33,11.33,0,0,1,2.59,3.77A12.13,12.13,0,0,1,622.29,847.73Zm-4.7,0a9.59,9.59,0,0,0-.51-3.11,7.88,7.88,0,0,0-1.47-2.61,7.06,7.06,0,0,0-2.38-1.79,8.22,8.22,0,0,0-6.5,0,7.06,7.06,0,0,0-2.38,1.79,7.86,7.86,0,0,0-1.47,2.61,9.82,9.82,0,0,0,0,6.22,8.13,8.13,0,0,0,1.47,2.63,6.91,6.91,0,0,0,2.38,1.82,8.22,8.22,0,0,0,6.5,0,6.92,6.92,0,0,0,2.38-1.82,8.14,8.14,0,0,0,1.47-2.63A9.58,9.58,0,0,0,617.59,847.76Z" transform="translate(-411.7 -815.24)"></path>
                            <path className="cls-1" d="M630.21,836.41q0.1,0.86.14,1.94t0,1.8h0.14a6.61,6.61,0,0,1,1.18-1.7,8.49,8.49,0,0,1,1.7-1.39,8.4,8.4,0,0,1,2.11-.94,8.54,8.54,0,0,1,2.4-.34,8.85,8.85,0,0,1,3.79.74,7.69,7.69,0,0,1,2.64,2,8.07,8.07,0,0,1,1.56,2.93,12.48,12.48,0,0,1,.5,3.6v14.11h-4.51V846.53a15.48,15.48,0,0,0-.22-2.64,6.36,6.36,0,0,0-.79-2.21,4.29,4.29,0,0,0-1.58-1.54,5.18,5.18,0,0,0-2.59-.58,5.46,5.46,0,0,0-4.39,2,8.22,8.22,0,0,0-1.7,5.45v12.1h-4.51V841.3q0-.91,0-2.35t-0.14-2.54h4.27Z" transform="translate(-411.7 -815.24)"></path>
                            <path className="cls-1" d="M656.95,828.2a2.69,2.69,0,0,1-.88,2.06,3,3,0,0,1-2.12.82,2.88,2.88,0,0,1-2.09-.84,2.75,2.75,0,0,1-.86-2,2.81,2.81,0,0,1,.86-2.09,2.88,2.88,0,0,1,2.09-.84,3,3,0,0,1,2.12.84A2.77,2.77,0,0,1,656.95,828.2Zm-0.72,31h-4.51V836.41h4.51v22.75Z" transform="translate(-411.7 -815.24)"></path>
                            <path className="cls-1" d="M677.64,842.17a5.53,5.53,0,0,0-2.16-1.85,6.6,6.6,0,0,0-3.07-.74,7,7,0,0,0-3.14.67,7.18,7.18,0,0,0-2.33,1.8,7.91,7.91,0,0,0-1.46,2.62,9.91,9.91,0,0,0,0,6.24,7.89,7.89,0,0,0,1.46,2.62,7.08,7.08,0,0,0,2.35,1.8,7.75,7.75,0,0,0,6.31,0,6.44,6.44,0,0,0,2.33-1.82l3,2.74a9.78,9.78,0,0,1-3.6,2.62,12.2,12.2,0,0,1-4.9.94,13.38,13.38,0,0,1-4.87-.86,11.55,11.55,0,0,1-3.86-2.42,11.17,11.17,0,0,1-2.57-3.77,12.55,12.55,0,0,1-.94-4.94,12.7,12.7,0,0,1,.91-4.9,11.13,11.13,0,0,1,2.54-3.79,11.36,11.36,0,0,1,3.86-2.45,13.25,13.25,0,0,1,4.82-.86,12.67,12.67,0,0,1,4.78,1,8.74,8.74,0,0,1,3.77,2.78Z" transform="translate(-411.7 -815.24)"></path>
                            </g>
                        </g>
                        <g id="Symbol">
                            <g>
                            <circle className="cls-1" cx="30.75" cy="30.98" r="12.69"></circle>
                            <circle className="cls-1" cx="47.89" cy="12.24" r="6.05"></circle>
                            <path className="cls-1" d="M467.28,831.7a8.57,8.57,0,0,1-4.14,3.46,23.47,23.47,0,1,1-11.31-10.45,8.56,8.56,0,0,1,3.14-4.41A28.83,28.83,0,1,0,467.28,831.7Z" transform="translate(-411.7 -815.24)"></path>
                            </g>
                        </g>
                        <g id="Elite">
                            <path className="cls-2" d="M500.65,844.57q0,0.62,0,1.27t-0.07,1.08h-17a2.16,2.16,0,0,0,.46,1.2,3.92,3.92,0,0,0,1,.91,4.58,4.58,0,0,0,1.34.58,6,6,0,0,0,1.51.19,5.4,5.4,0,0,0,2.57-.55A4.85,4.85,0,0,0,492,848l7.87,4a11.6,11.6,0,0,1-4.87,4.32,16.48,16.48,0,0,1-7.42,1.58,16.75,16.75,0,0,1-5.33-.86,13.57,13.57,0,0,1-4.58-2.57,12.52,12.52,0,0,1-3.19-4.25,13.69,13.69,0,0,1-1.2-5.9,13.9,13.9,0,0,1,1.1-5.64,12.63,12.63,0,0,1,3-4.27,13.43,13.43,0,0,1,4.46-2.71,15.57,15.57,0,0,1,5.5-1,13.83,13.83,0,0,1,5.42,1,12.31,12.31,0,0,1,4.2,2.88,13,13,0,0,1,2.71,4.39A15.52,15.52,0,0,1,500.65,844.57ZM491.1,841a2.81,2.81,0,0,0-1-2.21,3.89,3.89,0,0,0-2.66-.86,3.83,3.83,0,0,0-2.83,1,3.67,3.67,0,0,0-1.15,2.06h7.63Z" transform="translate(-411.7 -815.24)"></path>
                            <path className="cls-2" d="M504.54,820.66h11.33v36.29H504.54V820.66Z" transform="translate(-411.7 -815.24)"></path>
                            <path className="cls-2" d="M520.81,824.41a5.33,5.33,0,0,1,.46-2.18,5.68,5.68,0,0,1,1.25-1.8,6.11,6.11,0,0,1,1.85-1.22,5.63,5.63,0,0,1,2.26-.46,5.19,5.19,0,0,1,2.16.46,6.31,6.31,0,0,1,1.8,1.22,5.7,5.7,0,0,1,1.25,1.8,5.35,5.35,0,0,1,.46,2.18,5.46,5.46,0,0,1-.46,2.23,5.41,5.41,0,0,1-1.25,1.78,6.13,6.13,0,0,1-1.8,1.18,5.42,5.42,0,0,1-2.16.43,5.89,5.89,0,0,1-2.26-.43,6,6,0,0,1-1.85-1.18,5.39,5.39,0,0,1-1.25-1.78A5.45,5.45,0,0,1,520.81,824.41Zm0.19,7.3h11.09v25.25H521V831.7Z" transform="translate(-411.7 -815.24)"></path>
                            <path className="cls-2" d="M548.22,857.62q-5.09,0-7.54-2.18t-2.45-6.79v-9h-3.55V831.7h3.5V826h10.75v5.66h5.47v7.92h-5.47v6.72a3.09,3.09,0,0,0,.79,2.47,3.35,3.35,0,0,0,2.18.65,6.67,6.67,0,0,0,1.1-.1,8.53,8.53,0,0,0,.91-0.19v7.58a9.93,9.93,0,0,1-2.42.62A21.67,21.67,0,0,1,548.22,857.62Z" transform="translate(-411.7 -815.24)"></path>
                            <path className="cls-2" d="M583.78,844.57q0,0.62,0,1.27t-0.07,1.08h-17a2.16,2.16,0,0,0,.46,1.2,3.92,3.92,0,0,0,1,.91,4.58,4.58,0,0,0,1.34.58,6,6,0,0,0,1.51.19,5.4,5.4,0,0,0,2.57-.55,4.85,4.85,0,0,0,1.56-1.22l7.87,4a11.6,11.6,0,0,1-4.87,4.32,16.48,16.48,0,0,1-7.42,1.58,16.75,16.75,0,0,1-5.33-.86,13.57,13.57,0,0,1-4.58-2.57,12.52,12.52,0,0,1-3.19-4.25,13.69,13.69,0,0,1-1.2-5.9,13.9,13.9,0,0,1,1.1-5.64,12.63,12.63,0,0,1,3-4.27A13.43,13.43,0,0,1,565,831.7a15.57,15.57,0,0,1,5.5-1,13.83,13.83,0,0,1,5.42,1,12.31,12.31,0,0,1,4.2,2.88,13,13,0,0,1,2.71,4.39A15.52,15.52,0,0,1,583.78,844.57Zm-9.55-3.6a2.81,2.81,0,0,0-1-2.21,3.89,3.89,0,0,0-2.66-.86,3.83,3.83,0,0,0-2.83,1A3.67,3.67,0,0,0,566.6,841h7.63Z" transform="translate(-411.7 -815.24)"></path>
                        </g>
                        <g id="Ionic">
                            <path className="cls-2" d="M592.52,826a2.69,2.69,0,0,1-.88,2.06,3,3,0,0,1-2.12.82,2.88,2.88,0,0,1-2.09-.84,2.75,2.75,0,0,1-.86-2,2.81,2.81,0,0,1,.86-2.09,2.88,2.88,0,0,1,2.09-.84,3,3,0,0,1,2.12.84A2.77,2.77,0,0,1,592.52,826Zm-0.72,31h-4.51V834.2h4.51v22.75Z" transform="translate(-411.7 -815.24)"></path>
                            <path className="cls-2" d="M620.31,845.53a12.29,12.29,0,0,1-.94,4.85,11.52,11.52,0,0,1-2.59,3.82,11.69,11.69,0,0,1-3.91,2.5,13.67,13.67,0,0,1-9.7,0,11.42,11.42,0,0,1-3.89-2.5,11.7,11.7,0,0,1-2.57-3.82,12.29,12.29,0,0,1-.94-4.85,12.13,12.13,0,0,1,.94-4.82,11.51,11.51,0,0,1,2.57-3.77,11.63,11.63,0,0,1,3.89-2.47,13.67,13.67,0,0,1,9.7,0,11.9,11.9,0,0,1,3.91,2.47,11.33,11.33,0,0,1,2.59,3.77A12.13,12.13,0,0,1,620.31,845.53Zm-4.7,0a9.59,9.59,0,0,0-.51-3.11,7.88,7.88,0,0,0-1.47-2.61,7.06,7.06,0,0,0-2.38-1.79,8.22,8.22,0,0,0-6.5,0,7.06,7.06,0,0,0-2.38,1.79,7.86,7.86,0,0,0-1.47,2.61,9.82,9.82,0,0,0,0,6.22,8.13,8.13,0,0,0,1.47,2.63,6.91,6.91,0,0,0,2.38,1.82,8.22,8.22,0,0,0,6.5,0,6.92,6.92,0,0,0,2.38-1.82,8.14,8.14,0,0,0,1.47-2.63A9.58,9.58,0,0,0,615.6,845.55Z" transform="translate(-411.7 -815.24)"></path>
                            <path className="cls-2" d="M628.23,834.2q0.1,0.86.14,1.94t0,1.8h0.14a6.61,6.61,0,0,1,1.18-1.7,8.49,8.49,0,0,1,1.7-1.39,8.4,8.4,0,0,1,2.11-.94,8.54,8.54,0,0,1,2.4-.34,8.85,8.85,0,0,1,3.79.74,7.69,7.69,0,0,1,2.64,2,8.07,8.07,0,0,1,1.56,2.93,12.48,12.48,0,0,1,.5,3.6v14.11h-4.51V844.33a15.48,15.48,0,0,0-.22-2.64,6.36,6.36,0,0,0-.79-2.21,4.29,4.29,0,0,0-1.58-1.54,5.18,5.18,0,0,0-2.59-.58,5.46,5.46,0,0,0-4.39,2,8.22,8.22,0,0,0-1.7,5.45v12.1h-4.51V839.09q0-.91,0-2.35T624,834.2h4.27Z" transform="translate(-411.7 -815.24)"></path>
                            <path className="cls-2" d="M655,826a2.69,2.69,0,0,1-.88,2.06,3,3,0,0,1-2.12.82,2.88,2.88,0,0,1-2.09-.84,2.75,2.75,0,0,1-.86-2,2.81,2.81,0,0,1,.86-2.09,2.88,2.88,0,0,1,2.09-.84,3,3,0,0,1,2.12.84A2.77,2.77,0,0,1,655,826Zm-0.72,31h-4.51V834.2h4.51v22.75Z" transform="translate(-411.7 -815.24)"></path>
                            <path className="cls-2" d="M675.65,840a5.53,5.53,0,0,0-2.16-1.85,6.6,6.6,0,0,0-3.07-.74,7,7,0,0,0-3.14.67,7.18,7.18,0,0,0-2.33,1.8,7.91,7.91,0,0,0-1.46,2.62,9.91,9.91,0,0,0,0,6.24,7.89,7.89,0,0,0,1.46,2.62,7.08,7.08,0,0,0,2.35,1.8,7.75,7.75,0,0,0,6.31,0,6.44,6.44,0,0,0,2.33-1.82l3,2.74a9.78,9.78,0,0,1-3.6,2.62,12.2,12.2,0,0,1-4.9.94,13.38,13.38,0,0,1-4.87-.86,11.55,11.55,0,0,1-3.86-2.42,11.17,11.17,0,0,1-2.57-3.77,12.55,12.55,0,0,1-.94-4.94,12.7,12.7,0,0,1,.91-4.9,11.13,11.13,0,0,1,2.54-3.79,11.36,11.36,0,0,1,3.86-2.45,13.25,13.25,0,0,1,4.82-.86,12.67,12.67,0,0,1,4.78,1,8.74,8.74,0,0,1,3.77,2.78Z" transform="translate(-411.7 -815.24)"></path>
                        </g>
                        <g>
                            <circle className="cls-2" cx="28.77" cy="28.77" r="12.69"></circle>
                            <circle className="cls-2" cx="45.91" cy="10.03" r="6.05"></circle>
                            <path className="cls-2" d="M465.3,829.5a8.57,8.57,0,0,1-4.14,3.46,23.47,23.47,0,1,1-11.31-10.45A8.56,8.56,0,0,1,453,818.1,28.83,28.83,0,1,0,465.3,829.5Z" transform="translate(-411.7 -815.24)"></path>
                        </g>
                </svg>

              </Col>

            </Row>

            <Row>
              <Col xs={{size: 10, offset: 1}}>
                <h1>Log in</h1> 
                <p>To access this course you will need to provide your <strong>Order ID</strong> and the <strong>License Key</strong> from your purchase. If you have not already
                  purchased the course you can do so <a href="https://www.joshmorony.com/elite/">here</a>.
                </p>    
              </Col>
            </Row>
  
            <Row style={{marginTop: `20px`}}>
              <Col xs={{size: 10, offset: 1}}>
                <Form
                  method="post"
                  onSubmit={event => {
                    this.handleSubmit(event).then((res) => {
                      navigate('/angular');
                      console.log("logged in")
                    }, (err) => {
                      console.log("Error logging in");
                    })
  
                  }}
                >
                <FormGroup>
                  <Label style={{fontWeight: `bold`}}>Order ID</Label>      
                  <Input type="number" name="order" onChange={this.handleUpdate} />
                  <FormText color="#fff">
                    Your Order ID can be found in your purchase email, it will look something like this: <code>0034215467</code>.
                    Make sure that you <strong>do not</strong> include any special characters like <code>#</code> in your Order ID.
                  </FormText>    
                </FormGroup>
                <FormGroup>
                  <Label style={{fontWeight: `bold`}}>License Key</Label>          
                    <Input
                      type="text"
                      name="license"
                      onChange={this.handleUpdate}
                    />      
                  <FormText color="#fff">
                    Your License Key can be found in your purchase email, it will look something like this: <code>555F-5BAF-D5CE-5E5E</code>.
                    Please enter the license key exactly as it appears, including the hyphens.
                  </FormText>     
  
  
                    <Button disabled={this.state.loading} type="submit" color="info" style={{float: `right`, margin: `40px 0`, width: `100%`}}>
                        Log In
  
                        {
                          this.state.loading ? (
                            <FaRedo className="load-animation" style={{marginLeft: `5px`}} />
                          ) : (<></>)
                        }
  
                    </Button>
                  </FormGroup>
                </Form> 
              </Col>
            </Row>

            <Row>
              <Col xs={{size: 10, offset: 1}}>
                <Alert color="warning">
                  <strong>IMPORTANT:</strong> This course uses a cookie for authorisation. It is important that you have cookies enabled in order to access the course.
                </Alert>
              </Col>
            </Row>
    
          </Container>
  
        </LayoutLogin>
      )

    }

  }
}

export default Login